button {
  border: none;
  color: #555;
  background: #fff;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 3px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 500;
  box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2), 0 5px 8px rgba(0,0,0,0.14), 0 1px 14px rgba(0,0,0,0.12);
}
button:hover {
  background: #eee;
  color: #777;
}
button:active {
  animation: click 0.1s;
}
@-moz-keyframes click {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.95, 0.95);
  }
  100% {
    transform: scale(1, 1);
  }
}
@-webkit-keyframes click {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.95, 0.95);
  }
  100% {
    transform: scale(1, 1);
  }
}
@-o-keyframes click {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.95, 0.95);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes click {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.95, 0.95);
  }
  100% {
    transform: scale(1, 1);
  }
}
/*# sourceMappingURL=src/pages/ondemand/srf/sidebar.css.map */