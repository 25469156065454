







































































































.card-container
  display flex
  flex-direction column
  max-width 600px
  margin auto

.selected
  animation click 0.1s

@keyframes click
  0%
    transform scale(1, 1)
  50%
    transform scale(0.95, 0.95)
  100%
    transform scale(1, 1)
