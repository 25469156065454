
















































































































































































































































































.layout-padding
  max-width 786px
button
  width 100%

.flight-summary-one
  display flex
  flex-direction column
  margin 15px 0 0
  padding-bottom 10px
  box-shadow 0 1px 5px rgba(0 0 0 0.2), 0 2px 2px rgba(0 0 0 0.14), 0 3px 1px -2px rgba(0 0 0 0.12)

.timeline
  display grid
  grid-auto-flow column
  align-items center
  padding 15px 0 15px 0
  margin-right -50px

.timeline-section
  display grid
  grid-template-columns 1fr 1fr 50px
  grid-template-rows repeat(3, auto)
  grid-template-areas \
    "none none icon" \
    "none none icon" \
    "none none change-time"
  grid-row-gap 5px
  align-items center
  justify-content center
  text-align center

.joins
  align-self stretch
  display grid
  grid-template-columns 1fr 1fr
  align-items center
  justify-content stretch

.change
  grid-area icon
  justify-self center
  align-self flex-end

.flight-summary-two
  height 106px
  overflow hidden
  display grid
  grid-template-rows 1fr 10px 1fr
  grid-template-columns 100px auto 1fr auto 5px auto
  grid-template-areas \
  "logo start-iata changes end-iata separator flight-num" \
  "logo start-dot line end-dot separator flight-num" \
  "logo start-time journey-time end-time separator ..."
  align-items center
  justify-items center
  box-shadow 0 1px 5px rgba(0 0 0 0.2), 0 2px 2px rgba(0 0 0 0.14), 0 3px 1px -2px rgba(0 0 0 0.12)

.title
  grid-area title
  justify-self stretch
  padding 10px
  display flex
  justify-content space-between
  align-items center
  box-shadow 1px 0 rgba(0 0 0 0.3), -1px 0 rgba(0 0 0 0.3)

.logo
  grid-area logo
  width 75px

.start-iata
  grid-area start-iata
  padding 0 30px

.end-iata
  grid-area end-iata
  padding 0 30px

.changes
 grid-area changes

.start-time
  grid-area start-time

.end-time
  grid-area end-time

.dot
  height 6px
  width 6px
  background #dcdcdc
  border-radius 50%

.start-dot
  justify-self end

.line
  width 100%
  height 2px
  background #dcdcdc

.end-dot
  justify-self start

.leg-start-dot
  grid-area start-dot

.leg-line
  grid-area line

.leg-end-dot
  grid-area end-dot

.journey-time
  grid-area journey-time
  align-self flex-end
  margin-right 4%
  font-weight 600

.flight-num
  grid-area flight-num
  padding 5px 15px 0
  display flex
  flex-direction column
  align-items center
  justify-content center

.price
  grid-area price
  padding 0 15px 5px
  display flex
  flex-direction column
  align-items center
  justify-content center

.dropdown-enter
  height 0px

.dropdown-leave-to
  height 0px

.dropdown-enter-active
  transition height 0.5s

.dropdown-leave-active
  transition height 0.5s

.price-breakdown
  padding-left 25px
  font-size 110%
  display grid
  grid-template-columns 90px auto
  justify-items start
  grid-row-gap 5px

  