































































































.pricing
  border-style solid
  border-color $grey-4
  border-radius 0 !important
  border-width 0
  font-size 120%
  font-weight 450
  color black
  @media (min-width $breakpoint-md-min)
    border-left-width 1px
  @media (max-width $breakpoint-sm-min)
    border-top-width 1px

@media (max-width $breakpoint-sm-min)
  .mobile-block
    display block
@media (min-width $breakpoint-md-min)
  .bubble-change,
  .bubble-top,
  .bubble-line,
  .bubble-bottom
    position relative
    padding-left 8px
    &:before, &:after
      position absolute
      content ""
      background-color #DCDCDC
  .bubble-change,
  .bubble-top,
  .bubble-bottom
    &:before
      width 8px
      height 8px
      left -12px
      border-radius 100%
  .bubble-change:before
    top 6px
    border-radius 0
    transform rotate(45deg)
  .bubble-top:before
    bottom 8px
  .bubble-bottom:before
    top 8px
  .bubble-line:after
    width 2px
    top -8px
    bottom -8px
    left -9px
