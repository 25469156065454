
























































































































































.mobile
  .code
    display none
  .q-card
    width 100vw
    box-shadow none

.code
  height 25vh
  width 100%
  font-size 8vmax
  color rgba(255, 255, 255, .6)
  overflow hidden
  background-image url('~assets/backgrounds/flight.png')
  background-position: 0 30%;
  background-size: cover;
  text-overflow: clip;

.column.container
  margin-top -64px

.q-card
  width 80vw
  max-width 600px

.center
  justify-content center
  display flex

.q-page
  display flex
  width 100%
  flex-direction column
  align-items center
  background white

.oneway-return-holder
  display flex
  flex-direction row
  justify-content space-around
  color black
  label
    display flex
    align-items center

input[type="radio"]
  margin 12px
  width 20px
  height 20px
  border-radius 15px
  border 2px solid #666
  &:checked
    border 2px solid convert(brandColor)
    &:after
      width 14px
      height 14px
      bottom 2px
      left 1px
      border-radius 15px
      position relative
      background-color convert(brandColor)
      content ''
      display inline-block
      visibility visible
      border 2px solid white
