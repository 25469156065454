.card-container {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: auto;
}
.selected {
  animation: click 0.1s;
}
@-moz-keyframes click {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.95, 0.95);
  }
  100% {
    transform: scale(1, 1);
  }
}
@-webkit-keyframes click {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.95, 0.95);
  }
  100% {
    transform: scale(1, 1);
  }
}
@-o-keyframes click {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.95, 0.95);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes click {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.95, 0.95);
  }
  100% {
    transform: scale(1, 1);
  }
}
/*# sourceMappingURL=src/pages/ondemand/srf/results.css.map */